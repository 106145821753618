<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.0052 10.4874C14.3319 9.69883 14.5 8.85359 14.5 8C14.5 6.27609 13.8152 4.62279 12.5962 3.40381C11.3772 2.18482 9.72391 1.5 8 1.5C6.27609 1.5 4.62279 2.18482 3.40381 3.40381C2.18482 4.62279 1.5 6.27609 1.5 8C1.5 8.85359 1.66813 9.69883 1.99478 10.4874C2.32144 11.2761 2.80023 11.9926 3.40381 12.5962C4.00739 13.1998 4.72394 13.6786 5.51256 14.0052C6.30117 14.3319 7.14641 14.5 8 14.5C8.85359 14.5 9.69883 14.3319 10.4874 14.0052C11.2761 13.6786 11.9926 13.1998 12.5962 12.5962C13.1998 11.9926 13.6786 11.2761 14.0052 10.4874Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.39636 7.31568L7.43143 7.29858C7.54109 7.24379 7.66416 7.22158 7.78607 7.23457C7.90797 7.24756 8.02359 7.29521 8.11925 7.37188C8.2149 7.44855 8.28659 7.55103 8.32581 7.66718C8.36503 7.78332 8.37014 7.90828 8.34055 8.02724L7.73504 10.4527C7.70523 10.5717 7.71018 10.6968 7.74931 10.8131C7.78844 10.9294 7.86011 11.032 7.95581 11.1088C8.0515 11.1856 8.16722 11.2333 8.28923 11.2463C8.41124 11.2594 8.53441 11.2371 8.64416 11.1822L8.67922 11.1643"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.04521 4.98212H8.03784V4.98948H8.04521V4.98212Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
